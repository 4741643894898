@import "variables";
@import "../config";

mat-dialog-container {

  .kt-portlet__body {
    color: var(--primary-text-color) !important;
    font: $regular $font-l $pgmfont !important;
  }

  .kt-portlet__head-title {
    font: $medium $font-l $pgmfont !important;
    color: var(--primary-color) !important;
  }

  .mat-button.mat-button-base {
    height: 48px !important;
    padding: 6px 15px !important;
    border-radius: 2px !important;
    font: $bold $font-sm $pgmfont;
    text-transform: uppercase;
    box-shadow: none !important;
    background: $danger-transparent !important;
    color: $danger !important;
  }

  .mat-button.mat-button-base.mat-primary {
    background: $danger !important;
    color: $light !important;
  }
}
