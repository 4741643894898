@import "variables";
@import "../config";

// Table filter
.kt-form__filtration {
    margin: 0 0 5px !important;
}

.custom-field-holder {
    margin: 12px 0 !important;

    .mb-2.text-center {
        font: $regular $font-m $pgmfont;
        color: $label-color !important;
        text-transform: capitalize;
    }

    .ck.ck-editor__main>.ck-editor__editable {
        border-color: var(--border-color) !important;
        background-color: var(--background-input) !important;
        border-bottom-left-radius: 2px !important;
        border-bottom-right-radius: 2px !important;
        color: var(--primary-text-color) !important;
    }

    .ck-editor .ck-toolbar {
        color: var(--accent-color) !important;
        border-color: var(--border-color) !important;
        background-color: var(--background-input) !important;
        border-top-left-radius: 2px !important;
        border-top-right-radius: 2px !important;
    }

    .ck-toolbar__separator {
        background-color: var(--background-input) !important;
    }

    .ck.ck-reset_all,
    .ck.ck-reset_all * {
        color: var(--accent-color) !important;
        background: var(--background) !important;
    }

    .mat-error {
        color: $danger !important;
    }

    kt-time-input {
        color: $grey;

        input {
            border: none;
            background-color: var(--background-input);
            color: var(--primary-text-color);
            border-radius: 2px;

            &:focus {
                border: none !important;
            }
        }
    }

    &__image {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        .image-column {
            height: 150px;
            width: 150px;
            position: relative;

            img {
                width: 140px;
                height: 140px;
            }
        }

        .image-column .custom-file-label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: var(--primary-text-color);
            font-weight: $medium;
            border: none;
            height: 100%;
            cursor: pointer;

            i {
                color: $grey;
                font-size: $font-xxl;
                margin-bottom: 20px;
            }

            &:after {
                display: none;
            }
        }
    }
}

.custom-checkbox {
    .mat-checkbox-inner-container {
        height: $checkbox-height;
        width: $checkbox-width;
    }

    //   .mat-checkbox-frame {
    //     border: 1px solid $checkbox-color-default !important;
    //     border-radius: 4px;
    //   }

    span {
        font-weight: $regular;
        font-size: $font-m;
        color: var(--primary-text-color);
    }
}

// .custom-checkbox.mat-checkbox-indeterminate,
// .custom-checkbox.mat-checkbox-checked {
//   .mat-checkbox-background {
//     background: var(--primary-color) !important;
//   }
// }

.custom-search-input,
.custom-input {
    .mat-mdc-form-field-flex {
        background-color: var(--background-input) !important;
        border-radius: 2px !important;
        padding-top: 0 !important;
        align-items: center !important;
        color: var(--primary-text-color) !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline,
    .mat-form-field-appearance-legacy .mat-form-field-underline::before,
    .mat-form-field-appearance-fill .mat-form-field-underline::before {
        height: 0 !important;
    }

    .mat-form-field-infix {
        color: var(--primary-text-color);
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        border-width: 0 !important;
    }

    .mat-form-field-ripple,
    .mat-form-field-underline:before {
        height: 0 !important;
    }
}

.custom-search-input {
    font: $regular $font-m $pgmfont;
    height: 40px;

    input::-webkit-input-placeholder,
    input:-moz-placeholder,
    input::-moz-placeholder,
    input:-ms-input-placeholder {
        color: var(--primary-text-color) !important;
    }
}

.custom-search-label,
.custom-input .mat-form-field-label {
    font: $regular $font-m $pgmfont;
    color: $label-color !important;
    white-space: nowrap;
    max-width: 100%;
}

.custom-input {
    font: $regular $font-m $pgmfont;

    .mat-form-field-infix {
        padding-left: 10px !important;
    }

    .mat-form-field-underline {
        height: 0 !important;
    }

    .mat-hint {
        color: var(--accent-color) !important;
    }

    .mat-form-field-label-wrapper {
        top: -1.5em;
        padding-top: 1.5em;
        overflow: visible;
    }

    .mat-form-field-label {
        transform: translateY(-20px) scale(0.9) perspective(100px) translateZ(0.001px) !important;
    }

    textarea {
        height: 140px;
        padding-right: 5px !important;
    }

    &.dense {
        .mat-form-field-wrapper {
            padding: 0;
        }
    }

    //   .mat-select-arrow,
    //   .mat-datepicker-toggle-default-icon,
    //   .mat-form-field.mat-focused .mat-select-arrow {
    //     color: $danger !important;
    //   }
}

.custom-input-without-label {
    font: $regular $font-m $pgmfont;

    .mat-form-field-infix {
        padding-left: 10px !important;
    }

    .mat-form-field-underline {
        height: 0 !important;
    }

    .mat-hint {
        color: $input-color !important;
    }

    .mat-form-field-label-wrapper {
        display: none;
    }

    .mat-form-field-flex {
        background-color: var(--background-input) !important;
        border-radius: 2px !important;
        padding-top: 0 !important;
        align-items: center !important;
        color: var(--primary-text-color) !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline,
    .mat-form-field-appearance-legacy .mat-form-field-underline::before,
    .mat-form-field-appearance-fill .mat-form-field-underline::before {
        height: 0 !important;
    }

    .mat-form-field-infix {
        color: var(--primary-text-color);
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        border-width: 0 !important;
    }

    .mat-form-field-ripple,
    .mat-form-field-underline:before {
        height: 0 !important;
    }
}

// Global style changes

.mat-option {
    color: var(--accent-color) !important;

    &:hover {
        background-color: var(--background-2) !important;
    }
}

.mat-option.mat-selected:not(.mat-option-disabled) {
    background-color: var(--background-input);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    color: var(--primary-text-color) !important;
}

.description-ext {
    color: #8d9cb6 !important;
    font-weight: 500 !important;
}

.mat-select-panel {
    mat-option {
        .mat-option-pseudo-checkbox.mat-checkbox-inner-container {
            height: $checkbox-height;
            width: $checkbox-width;
        }

        .mat-pseudo-checkbox {
            border: 1px solid $checkbox-color-default !important;
            border-radius: 4px;
        }

        span {
            font-weight: $regular;
            font-size: $font-m;
            color: var(--primary-text-color);
        }

        .mat-pseudo-checkbox-checked,
        .mat-pseudo-checkbox-indeterminate {
            background: $danger !important;
            border-color: $danger !important;
        }
    }

    .mat-option.mat-selected:not(.mat-option-disabled),
    .mat-option.mat-active {
        background-color: $light-grey;
    }

    .mat-option:hover {
        background-color: $light-grey;
    }
}

.mat-form-field-plain-text {
    .mat-form-field-flex {
        background-color: transparent !important;
        border-bottom: 1px solid $light-grey;

        .mat-form-field-infix {
            padding-left: 0 !important;
            color: $input-color-readonly !important;
        }
    }
}

mat-option[ng-reflect-value="2147483647"]:last-child:before {
    content: "All";
    float: left;
    text-transform: none;
    top: 4px;
    position: relative;
}

mat-option[ng-reflect-value="2147483647"]:last-child .mat-option-text {
    display: none;
    position: relative;
}

.mat-tab-label.mat-tab-disabled {
    color: #aaa !important;
}

.input-color-picker-hint {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

.img-preview {
    display: flex;
    height: 140px;
    min-height: 140px;
    min-width: unset !important;
    max-width: unset !important;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    margin: 10px;
    border-radius: 5px;
    position: relative;

    img {
        max-height: 100%;
    }
}

.mat-select {
    .mat-select-value {
        max-width: none;
    }
}

// disabled inputs
.kt-form {
    .mat-select-disabled .mat-select-value-text {
        color: #666;
    }

    .mat-input-element:disabled,
    .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
        color: #666;
    }
}

textarea.mat-input-element {
    min-height: 150px;
}

.kt-form.readonly-form {
    .mat-form-field-flex {
        background: none !important;
        border-bottom: solid 1px #cbd0da;

        .mat-form-field-infix {
            padding-left: 0 !important;
        }
    }

    .mat-select-arrow-wrapper {
        display: none;
    }
}

.info-text-wrap {
    position: relative;
    min-height: 26px;

    .info-toggle-btn {
        height: 26px;
        width: 26px;
        line-height: 26px;

        position: absolute;
        right: 0;
        top: 0;
    }
}

.mat-form-field.with-tooltip {
    .custom-field-tooltip {
        position: absolute;
        top: -24px;
        left: 0;
        color: #6c757d;
    }

    .mat-form-field-label {
        padding-left: 24px;
    }
}


// .mat-mdc-form-field-flex {
//     min-height: 56px;
// }
.mat-mdc-form-field-text-prefix {
    height: 24px;
}
.mat-mdc-form-field-icon-suffix {
    display: flex;
}


.paginator-container .mat-mdc-form-field-flex {
    min-height: auto;
}

.mat-mdc-form-field-subscript-wrapper {
    font-size: 75%;
    color: var(--accent-text-color);
}