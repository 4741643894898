@use "@angular/material" as mat;
@use "palettes" as pal;
// Light Theme Text
$dark-text: #000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);
$fontConfig: (
    display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
    display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
    display-2: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
    display-1: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
    headline: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
    title: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
    subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
    subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
    body-2: mat.define-typography-level(14px, 24px, 400, 'Roboto', 0.0179em),
    body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
    button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
    caption: mat.define-typography-level(10px, 20px, 400, 'Roboto', 0.0333em),
    input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px)
);

$mat-light-theme-foreground: (
    base: #000,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-disabled-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text,
);

// Light bg
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
    background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
);

:root {
    --primary-color: #ee244b;
    --accent-color: #8d9cb6;
    --warn-color: rgb(255, 51, 51);
    --success-color: rgb(38, 127, 17);
    --danger-transparent: rgba(255, 51, 51, 0.2);
    --info-color: #03a9f4;

    --border-radius: 10px;

    --primary-text-color-full: #{$dark-text};
    --primary-text-color: #{$dark-text};
    --accent-text-color: #{$dark-accent-text};
    --warn-text-color: #{$dark-text};

    --border-color: rgba(0, 0, 0, 0.16);
    --border-color-2: rgba(0, 0, 0, 0.08);
    --border-color-inverse: rgba(255, 255, 255, 0.16);
    --box-shadow-color-1: rgba(82, 63, 105, 0.1);
    --mat-box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024,
        0 3px 14px 2px #0000001f;

    --background: #{$light-background};
    --background-1: #{$light-bg-darker-5};
    --background-2: #{$light-bg-darker-10};
    --background-3: #{$light-bg-darker-20};
    --background-4: #{$light-bg-darker-30};
    --background-paper: #fff;
    --background-input: #f4f7fc;

    --txt-title: #{$dark-text};
    --txt-base: #{$dark-primary-text};
    --txt-caption: #{$dark-accent-text};
    --txt-disabled: #{$dark-disabled-text};
    --divider: #{$dark-dividers};
}

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
    base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-disabled-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3),
);

// Dark bg
$dark-background: rgba(32, 33, 36, 1);
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#f9f3e9, 0.04);
$light-bg-alpha-12: rgba(#f9f3e9, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
    background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-background,
    dialog: $dark-background,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10,
);

:root {
    .dark {
        --primary-color: #ee244b;
        --accent-color: #8d9cb6;
        --warn-color: #ff3333;
        --success-color: rgb(38, 127, 17);
        --danger-transparent: rgba(255, 51, 51, 0.2);
        --info-color: #03a9f4;
        --inverse-accent-color: rgb(173, 216, 255);

        --border-radius: 10px;

        --primary-text-color-full: #{$light-text};
        --primary-text-color: #{$light-text};
        --accent-text-color: #{$light-accent-text};
        --warn-text-color: #{$light-text};

        --border-color: rgba(255, 255, 255, 0.16);
        --border-color-2: rgba(255, 255, 255, 0.08);
        --border-color-inverse: rgba(0, 0, 0, 0.16);
        --box-shadow-color-1: rgba(82, 63, 105, 0.1);
        --mat-box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024,
            0 3px 14px 2px #0000001f;

        --background: #{$dark-background};
        --background-1: #{$dark-bg-lighter-5};
        --background-2: #{$dark-bg-lighter-10};
        --background-3: #{$dark-bg-lighter-20};
        --background-4: #{$dark-bg-lighter-30};
        --background-paper: var(--background-1);
        --background-input: var(--background-1);

        --txt-title: #{$light-text};
        --txt-base: #{$light-primary-text};
        --txt-caption: #{$light-accent-text};
        --txt-disabled: #{$light-disabled-text};
        --divider: #{$light-dividers};
    }
}

$cb-theme-primary: mat.define-palette(pal.$cb-primary);
$cb-theme-accent: mat.define-palette(pal.$cb-accent);
$cb-theme-warn: mat.define-palette(pal.$cb-warn);
$my-typography: $fontConfig;
// $my-typography: mat.define-typography-config();


$cbTheme: (
    color: (primary: $cb-theme-primary,
        accent: $cb-theme-accent,
        warn: $cb-theme-warn,
    ),
    typography: $my-typography,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
    density: -1
);

$cbThemeDark: (
    color: (primary: $cb-theme-primary,
        accent: $cb-theme-accent,
        warn: $cb-theme-warn,
    ),
    typography: $my-typography,
    is-dark: true,
    foreground: $mat-dark-theme-foreground,
    background: $mat-dark-theme-background,
    density: -1
);