@import "variables";
@import "../config";

.table-container,
.table-container__wide {

    // .mat-header-row,
    // .mat-row {
    //   align-items: stretch;
    //   border-bottom-color: $light-grey;
    //   width: min-content;
    //   min-width: 100%;
    //   min-height: 36px !important;
    // }
    // .mat-mdc-row {
        // height: unset !important;
    // }

    .email-cell {
        min-width: 150px !important;
    }

    .phone-cell,
    .fax-cell {
        min-width: 80px;
    }

    .mat-column-id {
        word-wrap: break-word;
    }

    .mat-column-api_token {
        word-break: break-word;
    }
}

.mat-mdc-header-cell,
.mat-mdc-cell {
    .cell-content-wrap {
        display: block;
        padding-right: 10px;
        padding-left: 10px;

        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        //    white-space: nowrap;
    }

    &.mat-column-actions {
        padding: 0;
        .cell-content-wrap {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
        }
    }
}

.mat-header-cell:not(.mat-column-checkbox),
.mat-cell:not(.mat-column-checkbox) {
    &.mat-column-actions {
        flex-basis: auto;
        width: auto;
        overflow: visible;
        min-width: fit-content;
        white-space: nowrap;

        .mat-icon-button {
            width: 30px;

            .fa,
            .fas,
            .far,
            .fal,
            .fad,
            .fab,
            .mat-icon {
                font-size: 1.33333em;
            }
        }
    }
}

.mat-header-cell {
    .cell-content-wrap {
        text-align: left;
    }
}

.mat-mdc-cell:not(.mat-column-checkbox) {
    &:not(.mat-column-actions) {
        padding-top: 12px;
        padding-bottom: 12px;
        white-space: nowrap;
        padding: 0;
    }

    &.col-type-text {
        .cell-content-wrap {
            // min-width: 150px;
        }
    }

    // &.col-type-boolean {
    //     .cell-content-wrap {
    //         min-width: 80px;
    //     }
    // }

    &.col-type-datetime {
        .cell-content-wrap {
            min-width: 140px;
        }
    }

    &.col-type-date {
        .cell-content-wrap {
            min-width: 100px;
        }
    }
}

.mat-mdc-header-cell.mat-column-checkbox,
.mat-mdc-header-cell.mat-column-actions {
    width: 40px !important;
}

// .mat-row-first-child-actions::after {
//   position: absolute;
//   height: 100%;
//   top: -100%;
//   background: white;
//   z-index: 100;
//   display: block;
//   content: "";
//   left: -10px;
//   right: 0;
//   border-bottom: solid 1px #eee;
//   border-left: solid 1px #eee;
// }

.table-header-cell,
.table-content-cell,
.mat-column-checkbox {
    position: relative;

    &:not(:last-child):not(:nth-last-child(1)):after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 10px;
        height: calc(100% - 20px);
        width: 1px;
        background: var(--border-color);
        z-index: 0;
    }
}

.mat-mdc-header-cell.mat-column-actions:before,
.mat-mdc-cell.mat-column-actions:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 10px;
    height: calc(100% - 20px);
    width: 1px;
    border-left: solid 1px var(--border-color);
    z-index: 10;
    background: var(--background)
}

.table-header-cell,
.table-header-cell *:not(.far) {
    color: var(--accent-color) !important;
    // font: $regular $font-sm $pgmfont;
    font-size: .875rem;
    white-space: nowrap;
    //   overflow-x: hidden;
    text-overflow: ellipsis;
}

.inQuickColumnEditorMode {
    .mat-sort-header-container {
        padding-left: 46px;
    }

    .mat-sort-header-arrow {
        display: none;
    }
}

.table-content-cell,
.table-content-cell * {
    color: var(--primary-text-color) !important;
    // font: $regular $font-m $pgmfont;
    // font: $regular $font-sm $pgmfont !important;
    font-size: .875rem !important;
    word-break: break-word;
    hyphens: auto;
}

.table-content-cell .kt-link:hover:after {
    border-bottom-color: var(--primary-text-color);
}

.table-subtitle span {
    font: $medium $font-l $pgmfont;
    color: $danger;
}

.format-color {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
}

.filters {
    .mat-expansion-panel-header-title {
        display: flex;
        align-items: center;

        h5 {
            margin: 0;
        }
    }
}

.filtered-indicators {
    display: flex;
    align-items: center;
}