/* You can add global styles to this file, and also import other style files */
// @import "@angular/material/prebuilt-themes/indigo-pink.css";

//syncfunsion
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-kanban/styles/material.css";

// header base skins
.kt-header-base-light {
    @import "sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
    @import "sass/global/layout/header/skins/base/dark.scss";
}

// header menu skins
.kt-header-menu-light {
    @import "sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
    @import "sass/global/layout/header/skins/menu/dark.scss";
}

// brand skins
.kt-brand-dark {
    @import "sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
    @import "sass/global/layout/brand/skins/light.scss";
}

// aside skins
.kt-aside-dark {
    @import "sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
    @import "sass/global/layout/aside/skins/light.scss";
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
    display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
    min-width: 600px !important;
    width: 100% !important;
}

.position-static {
    position: static;
}

.waiting {
    cursor: wait !important;
}

// email global css
.cdk-global-scrollblock {
    overflow-y: hidden !important;
}

.new-email-dialog,
.fullscreen-modal,
.fullscreen {
    min-height: 100vh;
    min-width: 100vw !important;
    height: 100%;
}

.fullscreen-modal {

    &.mat-bottom-sheet-container-xlarge,
    &.mat-bottom-sheet-container-large,
    &.mat-bottom-sheet-container-medium {
        min-width: 100vw !important;
        min-height: 100vh !important;
    }

    &.mat-bottom-sheet-container {
        padding: 0;
        //   background-color: var(--background);

        &.fullscreen-modal {
            max-height: 100vh !important;
        }
    }

    .mat-progress-bar {
        height: 10px !important;
        border-radius: 4px !important;

        .mat-progress-bar-fill::after {
            background-color: var(--accent-color) !important;
        }
    }
}

.mat-form-field.w-all {
    .mat-form-field-infix {
        width: 100% !important;
    }
}

.btn-outline-primary {
    border: 1px solid var(--border-color-2) !important;
}

.mat-mdc-tab-group.header-less-tabs>.mat-mdc-tab-header {
    display: none;
}

.ngx-toastr {
    .toast-title {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2.5rem;
        text-transform: capitalize;
    }

    .toast-message {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
}

* {
    scrollbar-width: auto;
    scrollbar-color: var(--background-1);

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: var(--background);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--primary-color);
    }
}

.fullscreen-modal {
    header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 12px 24px;
        font-size: 1.4rem;
        font-weight: 900;

        .icon {
            font-size: 1.6rem;
            font-weight: 900;
            line-height: 2.5rem;
        }

        &.fullscreen {
            min-height: 0;
            position: absolute;
            padding: 0 24px;
            top: 0;
            height: 64px;
            left: 0;
            z-index: 2;
            box-shadow: 0 3px 4px 0 rgb(0 0 0 / 30%);
            background-color: var(--background);
        }
    }

    .content-wrapper {
        padding: 24px 0;
        position: relative;
        display: flex;
        flex-direction: row;
        margin: 48px auto 24px;
        width: 100%;
    }
}

.mat-loading .mdc-button__label {
    visibility: hidden;
}

.mat-loading .mat-mdc-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
}

.mat-button-toggle span {
    line-height: 36px !important;
}

.pgmcard {
    border: 1px solid var(--border-color-2);
    border-radius: var(--border-radius);
    padding: 15px !important;

    .header-card {
        margin: 0;
    }
}

.card .card-body .mainWrapper {
    padding: 25px !important;
}

.table-content-cell .cb-router-link,
.table-content-cell .table-link {
    // text-decoration: underline;
    // color: var(--primary-color) !important;
    text-decoration: underline dashed grey; // var(--primary-color);
    text-underline-offset: 2px;

    i {
        color: var(--info-color) !important;
        font-family: "Font Awesome 6 Pro" !important; // this is bad, but we cannot do anything about it at the moment
    }
}

.mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

// border radius overrides
.mat-mdc-menu-panel,
.mat-mdc-flat-button,
.mat-mdc-stroked-button,
.mat-mdc-raised-button {
    border-radius: var(--border-radius) !important;
    min-width: 32px;
    min-height: 32px;
}

.mat-accordion .mat-expansion-panel:last-of-type {
    border-bottom-right-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
}

.mat-accordion .mat-expansion-panel:first-of-type {
    border-top-right-radius: var(--border-radius) !important;
    border-top-left-radius: var(--border-radius) !important;
}

// button overrides

.mat-mdc-unelevated-button {
    &[color="success"] {
        background-color: var(--success-color) !important;
        color: #fff !important;
    }

    &.mat-success-border {
        border: var(--success-color) solid 1px;
        background-color: white;
        color: var(--success-color);
    }

    &[color="info"] {
        background-color: var(--info-color) !important;
        color: #fff !important;
    }
}

.mat-table__bottom {
    background-color: var(--background);
    border-bottom-right-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
    padding-inline: 1rem;
    border-top: 1px solid var(--border-color-2);
}

.mat-mdc-tab-body-wrapper {
    background: var(--background-1);
}


.cb-paper {
    background-color: var(--background);
    border-radius: var(--border-radius);
}

.collapsible .cb-paper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.filters .mat-expansion-panel-body {
    padding: 0 !important;
}

.large-menu.mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: 400px;
}

.cdk-drag-preview.table-column-preview>* {
    width: 100%;

}