// Custom Theming for Angular Material
@use "@angular/material" as mat;
@use "themes/cb-theme" as cb-theme;
@import "@angular/material/theming";

// @include mat.all-component-themes();
@include mat.core();

$cbLightTheme: mat.define-light-theme(cb-theme.$cbTheme);
$cbDarkTheme: mat.define-dark-theme(cb-theme.$cbThemeDark);


@include mat.all-component-themes($cbLightTheme);

.dark {
  @include mat.all-component-colors($cbDarkTheme);
}

.mat-tab-body-content {
  padding-top: 0 !important;
}
