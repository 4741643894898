
.card {
    background-color: transparent !important;
}

.dark {
    .card-header {
        background-color: var(--background-1);
        color: var(--primary-text-color);
    }
    .card {
        background-color: var(--background);
    }
}
