@import "variables";
@import "../config";

// Header styles
.kt-header {
    z-index: 102 !important; 
}
.custom-header {
  .kt-menu__link-text {
    font: $medium $font-m $pgmfont !important;
    color: var(--primary-text-color) !important;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-menu__link-icon {
    color: var(--accent-color) !important;
    font-size: $font-xxl !important;
    min-width: 25px !important;
  }

  .kt-menu__nav > .kt-menu__item:hover > .kt-menu__link {
    background-color: transparent !important;
  }

  .kt-menu__link:hover .kt-menu__link-text {
    color: var(--accent-color) !important;
  }

  .kt-menu__nav > .kt-menu__item.kt-menu__item--here,
  .kt-menu__nav > .kt-menu__item.kt-menu__item--active {
    border-bottom: 3px solid var(--primary-color);
  }

  .kt-menu__nav > .kt-menu__item.kt-menu__item--here > .kt-menu__link,
  .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link {
    background-color: transparent !important;

    .kt-menu__link-text,
    .kt-menu__link-icon {
      color: var(--primary-color) !important;
    }
  }

  .kt-header__topbar-welcome,
  .kt-header__topbar-username {
    font-size: $font-m !important;
    color: var(--accent-color) !important;
  }
}

.custom-subheader {
  height: auto !important;

  .kt-subheader__main {
    padding: 24px 0 !important;
  }

  .kt-subheader__title {
    font: $medium $font-xxxl $pgmfont !important;
    color: var(--primary-text-color);
  }
}

.custom-user-menu__header {
  .kt-header__topbar-welcome {
    font-size: $font-m !important;
  }
}

.custom-user-menu__content {
  .kt-user-card {
    background-color: transparent;
    border-bottom: 3px solid var(--primary-color);
  }

  .kt-user-card__name {
    color: var(--primary-color) !important;
  }

  .kt-notification__item-icon i {
    color: var(--accent-color) !important;
  }

  .kt-notification__item-details * {
    color: var(--primary-text-color) !important;
    font: $regular $font-m $pgmfont;
  }

  .kt-nav__item:hover .kt-nav__link,
  .kt-notification__item:hover {
    * {
      color: var(--accent-color) !important;
    }

    background-color: transparent !important;
  }

  .kt-nav__link-icon {
    font-size: $font-xxl !important;
  }

  .kt-nav__link-text {
    font: $regular $font-m $pgmfont;
    color: var(--primary-text-color) !important;
  }

  .kt-notification .kt-notification__custom {
    border-top: none;
  }

  .signout-link {
    color: var(--primary-text-color) !important;
    font: $regular $font-m $pgmfont;
    display: flex;
    width: 100%;
    padding: 1.6rem 1.5rem;
    align-items: center;

    i {
      font-size: $font-xxxl;
      margin-right: 1rem;
      color: var(--accent-color);
    }

    &:hover {
      color: var(--accent-color) !important;
    }
  }
}

.custom-user-menu__content .kt-notification__item {
  border-bottom: 1px solid var(border-color-1);
}

#kt_header {
  .test-data-switch {
    height: 65px;
    line-height: 65px;
  }

  .header-logo-wrap {
    width: auto;
    display: flex;    

    .kt-aside__brand-tools {
      display: none;
    }
  }

  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon {
    // display: none;
    min-width: 0 !important;
    width: auto;
    padding-right: 4px;
  }
}

// Desktop mode
@include kt-desktop {
  // Page
  .kt-wrapper {
    // Fixed Content Head
    .kt-header--fixed.kt-subheader--fixed.kt-subheader--enabled & {
      padding-top: kt-get($kt-subheader-config, fixed, height);
    }
  }

  // Subheader
  .kt-subheader.custom-subheader {
    // Fixed subheader mode
    .kt-header--fixed.kt-subheader--fixed & {
      top: 0;

      &.has-menu-horizontal {
        top: 40px;
      }
    }
  }
}
