@import "variables";
@import "../config";

// Aside menu styles

.custom-aside-link {
  background: transparent !important;

  .kt-menu__link-text {
    font: $medium $font-m $pgmfont !important;
    color: $dark !important;
    transition: all .25s;
  }

  .kt-menu__link-icon {
    color: $grey !important;
    font-size: $font-xxxl !important;
  }

  &:hover {
    .kt-menu__link-text {
      color: $grey !important;
    }
  }

  .kt-menu__ver-arrow {
    font-size: $font-s !important;
    font-weight: $bold;
    color: $grey !important;
  }
}

.custom-aside {
  font: $medium $font-m $pgmfont;
  color: $dark;

  .example-margin span {
    vertical-align: text-bottom;
  }

  .kt-menu__item--here > .custom-aside-link,
  .kt-menu__item--active {
    .kt-menu__link-text,
    .kt-menu__link-icon {
      color: $danger !important;
    }
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $danger-transparent !important;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $danger !important;
  }

}



.kt-aside--minimize {
  .kt-aside {
    .test-data-switch {

      .label {
        display: none;
      }

      .mat-slide-toggle {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}
