.paginator-container {
    font-size: .875rem;
    color: var(--accent-text-color);

    .mat-mdc-icon-button.mat-mdc-button-base {
        padding: 6px;
    }
}

.mat-mdc-paginator {
    color: var(--accent-text-color) !important;
}