// Buttons
.mat-fab,
.mat-mini-fab,
.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-icon-button {
  outline: none !important;
}

mat-radio-button {
  padding-right: 16px;
}

mat-radio-button {
  .mat-radio-label {
    margin-bottom: 0;
  }
}

.cb-icon-button {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    &>*[role=img] {
        width: 20px;
        height: 20px;
        font-size: 20px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    i {
        font-size: large !important;

        // our custom icons, are smaller than the default font-awesome icons
        // server passes those icons with the following class
        &.fa-2x {
            font-size: 1em !important;
        }
    }

    .mat-mdc-button-touch-target {
        width: 24px !important;
        height: 24px !important;
    }
}

