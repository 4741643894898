//
// Main Stylesheet
//




////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Include global config
@import "config";

// 2: Bootstrap framework includes
@import "global/integration/frameworks/bootstrap/variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "global/integration/frameworks/bootstrap/bootstrap.scss";

// Material Angular
@import "@angular/material/theming";
@import "global/integration/frameworks/angular/material/angular-material";
@import "global/integration/frameworks/angular/material/include";

// 3: Components include
@import "global/include.angular";

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 4: Layout config
@import "global/layout/config";
@import "global/layout/header/config";
@import "global/layout/brand/config";
@import "global/layout/aside/config";
@import "global/layout/footer/config";


// 5: Layout includes
@import "global/layout/base/base";
@import "global/layout/header/header";
@import "global/layout/header/base-mobile";
@import "global/layout/header/menu";
@import "global/layout/header/topbar";
@import "global/layout/brand/brand";
@import "global/layout/aside/aside";
@import "global/layout/content/content";
@import "global/layout/footer/footer";

// 6:  User defined custom styles includes
@import "custom/common";
@import "custom/variables";
@import "custom/dialog";
@import "custom/aside";
@import "custom/header";
@import "custom/tables";
@import "custom/forms";
@import "custom/calendar";
@import "custom/bootstrapOverrides";

:root {
    // these are used to calculate the max available height for tables
    --navbar-height: 40px;
    --searchBar-height: 58px;
    --footer-height: 40px;
    --available-body-height: calc(100vh - var(--navbar-height) - var(--searchBar-height) - var(--footer-height) - 250px);
    --searchBar-max-width: 50%;
}

.mat-paginator-range-label {
    margin: 0 12px !important;
}

