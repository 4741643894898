@import "variables";

body {
    background-color: var(--background-1) !important;
}

.kt-content {
    padding: 50px 0;
}

// // Styles for button with icon
// .custom-icon-button {
//   box-shadow: none !important;
//   padding: 0 !important;
//   height: 20px !important;
//   width: 20px !important;
//   min-width: 0 !important;
//   display: flex !important;
//   justify-content: flex-end;
//   align-items: center;

//   .mat-raised-button[disabled][disabled] {
//     background-color: transparent;
//     opacity: 0.8;
//   }

//   .material-icons {
//     //   font-size: 22px !important;
//     font-size: 18px !important;
//     padding: 0 0px;
//   }

//   i {
//     font-size: $font-xxxl;
//   }
// }

// Icons styles
.edit-icon {
    color: var(--accent-color) !important;
}

.menu-icon {
    color: var(--accent-color) !important;
}

.mail-icon {
    color: var(--accent-color) !important;
    width: 24px !important;
}

.delete-icon {
    color: var(--warn-color) !important;
}

.add-icon {
    color: var(--accent-color) !important;
}

// Buttons styles

.reset-button-styles {
    box-shadow: none !important;
    color: var(--primary-text-color) !important;
    font: $bold $font-m $pgmfont;

    &:hover,
    &:focus,
    &:active,
    &:disabled {
        background: transparent !important;
    }

    &.disabled,
    &:disabled {
        opacity: 0.45;
    }
}

.danger-button,
.danger-button-inverse,
.primary-button {
    height: 48px !important;
    padding: 6px 15px !important;
    border-radius: 2px !important;
    font: $bold $font-sm $pgmfont;
    text-transform: uppercase;
    box-shadow: none !important;

    &:not(:last-child) {
        margin-right: 16px !important;
    }

    &.w-30 {
        width: 30%;
    }
}

.danger-button {
    background: var(--warn-color) !important;
    color: $light !important;
}

.danger-outline-button {
    i {
        color: var(--warn-color) !important;
        font-weight: bold;
    }
}

.warning-outline-button {
    i {
        color: $warning !important;
        font-weight: bold;
    }
}

.success-outline-button {
    i {
        color: $success !important;
        font-weight: bold;
    }
}

.info-outline-button {
    i {
        color: $info !important;
        font-weight: bold;
    }
}

.danger-button-inverse {
    background: var(--warn-color)-transparent !important;
    color: var(--warn-color) !important;
}

.primary-button {
    color: var(--accent-color) !important;
    background: var(--accent-color)-transparent !important;
}

// Link styles
.danger-link {
    color: var(--warn-color);
    text-transform: capitalize;

    &:hover {
        color: var(--warn-color);
        text-decoration: underline;
    }
}

.btn-facebook {
    color: #fff !important;
    background-color: #3b5998 !important;
    border-color: #3b5998 !important;
}

.btn-twitter {
    color: #fff !important;
    background-color: #41abe1 !important;
    border-color: #41abe1 !important;
}

.kt-btn {
    border-radius: 2px !important;
}

button:not(.mat-sort-header-button)[disabled],
.btn.disabled:not(.mat-sort-header-button) {
    opacity: 0.4 !important;
}

// Tabs styles
.custom-tabs {

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-top-width: 3px;
    }

    .mdc-tab__text-label {
        text-transform: capitalize;
        opacity: 1;
        letter-spacing: normal;
    }

    .mat-mdc-tab-label {
        //font: 500 $font-m $pgmfont;
        // font: 500 $font-l $pgmfont;
        // color: var(--primary-text-color) !important;

        //border-top-left-radius: 20px;
        //border-top-right-radius: 20px;
        //border: solid 1px;
        //border-top-color: $light-grey !important;
        //border-left-color: $light-grey !important;
        //border-right-color: $light-grey !important;

        padding-left: 10px !important;
        padding-right: 10px !important;

        min-width: 100px !important;
    }

    .mat-mdc-tab-nav-bar,
    .mat-mdc-tab-header {
        border-bottom: 1px solid var(--border-color-2);
        background-color: var(--background-1);
    }

    //   .mat-tab-label-active.mat-tab-label {
    //     color: var(--primary-color) !important;
    //   }


    .mat-tab-body.mat-tab-body-active {
        height: fit-content;
    }
}

// Styles for title
.title-danger .kt-portlet__head-title {
    color: var(--primary-color) !important;
    font-size: $font-xxl !important;
}

.w-140 {
    width: 140px;
}

// Dialog styles
.calendar-event-details {
    font: $medium $font-m $pgmfont;
    color: var(--primary-text-color);

    .dialog-header {
        display: flex;
        justify-content: space-between;

        .mat-dialog-title {
            color: var(--warn-color);
            font-size: $font-xxxl;
        }
    }

    dt {
        color: var(--accent-color);
    }
}

// Spinner style
.mat-spinner circle {
    stroke: var(--primary-color) !important;
}

// Styles for Login/Register
.kt-login__signup-link {
    font-weight: $bold !important;
}

.kt-login__title {
    margin-top: 4rem;
    margin-bottom: 3rem !important;
}

.kt-login__title h3 {
    color: var(--primary-color) !important;
    font-family: "Roboto", sans-serif;
}

kt-auth-notice .alert.alert-info {
    background-color: var(--accent-color);
    color: $light-grey;
    border: none;
    border-radius: 2px;
}

// Styles for language dropdown list
.custom-language-list {
    .kt-nav__link {
        border-bottom: 3px solid transparent;
    }

    .kt-nav__link-text {
        color: var(--primary-text-color) !important;
        font-family: "Roboto", sans-serif;
        font-weight: $medium !important;
    }

    .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)>.kt-nav__link {
        background-color: transparent !important;

        .kt-nav__link-text {
            color: var(--accent-color) !important;
        }
    }

    .kt-nav__item--active>.kt-nav__link {
        background-color: transparent !important;

        .kt-nav__link-text {
            color: var(--warn-color) !important;
        }
    }
}

.text-ghosted {
    color: #ccc !important;
}

.custom-tabs.mat-tab-group {
    .mat-tab-body-wrapper {
        overflow: initial;

        .mat-tab-body-active {
            overflow: hidden;
        }
    }
}

.inline-seperator {
    display: inline-block;
    width: 1px;
    border-left: solid 1px var(--border-color-2);
}

/* mat-button */
.custom-mat-button {
    &.mat-button.mat-button-base {
        height: 48px !important;
        padding: 6px 15px !important;
        border-radius: 2px !important;
        font: $bold $font-sm $pgmfont;
        text-transform: uppercase;
        box-shadow: none !important;
        background: var(--warn-color)-transparent !important;
        color: var(--warn-color) !important;
    }

    &.mat-button.mat-button-base.mat-primary {
        background: var(--warn-color) !important;
        color: $light !important;
    }
}

.info-help-icon {
    font-size: 1.4em;
}

.anim-shake {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;

    animation-timing-function: ease-out;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    1% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    2% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    3% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    4% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    5% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    6% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    7% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    8% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    9% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    10% {
        transform: translate(1px, -2px) rotate(-1deg);
    }

    11% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    100% {
        transform: translate(1px, 1px) rotate(0deg);
    }
}