.sidenav {
    width: 65%;
    min-width: 250px;
    max-width: 350px;
}
.sidenav-content {
    display: flex;
    flex-direction: column;
    height: 100vH;
}
.toolbar {
    position: relative;
    z-index: 1;
    flex-grow: 0;
    flex-shrink: 0;
}
.content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: 24px;
}

::ng-deep router-outlet + * {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

app-root, mat-sidenav-container {
    min-height: 100%;
}
